.fire {
  font-size: 24px;
  filter: blur(0.02em);
  -webkit-filter: blur(0.02em);
  position: relative;
  height: 100px;
  z-index: 1;
}

.particle {
  animation: rise 1s ease-in infinite;
  background-image: radial-gradient(#ff5000 20%, rgba(255, 80, 0, 0) 70%);
  border-radius: 50%;
  mix-blend-mode: screen;
  opacity: 0;
  position: absolute;
  bottom: 0;
  width: 5em;
  height: 5em;
}
.particle:nth-of-type(1) {
  animation-delay: 0.2761464832s;
  left: calc((100% - 5em) * 0);
}
.particle:nth-of-type(2) {
  animation-delay: 0.1857619496s;
  left: calc((100% - 5em) * 0.02);
}
.particle:nth-of-type(3) {
  animation-delay: 0.0094896058s;
  left: calc((100% - 5em) * 0.04);
}
.particle:nth-of-type(4) {
  animation-delay: 0.1924034499s;
  left: calc((100% - 5em) * 0.06);
}
.particle:nth-of-type(5) {
  animation-delay: 0.631892276s;
  left: calc((100% - 5em) * 0.08);
}
.particle:nth-of-type(6) {
  animation-delay: 0.060578338s;
  left: calc((100% - 5em) * 0.1);
}
.particle:nth-of-type(7) {
  animation-delay: 0.8917568922s;
  left: calc((100% - 5em) * 0.12);
}
.particle:nth-of-type(8) {
  animation-delay: 0.6470949325s;
  left: calc((100% - 5em) * 0.14);
}
.particle:nth-of-type(9) {
  animation-delay: 0.691002988s;
  left: calc((100% - 5em) * 0.16);
}
.particle:nth-of-type(10) {
  animation-delay: 0.2280960843s;
  left: calc((100% - 5em) * 0.18);
}
.particle:nth-of-type(11) {
  animation-delay: 0.7734943697s;
  left: calc((100% - 5em) * 0.2);
}
.particle:nth-of-type(12) {
  animation-delay: 0.9243254717s;
  left: calc((100% - 5em) * 0.22);
}
.particle:nth-of-type(13) {
  animation-delay: 0.3715292992s;
  left: calc((100% - 5em) * 0.24);
}
.particle:nth-of-type(14) {
  animation-delay: 0.445524776s;
  left: calc((100% - 5em) * 0.26);
}
.particle:nth-of-type(15) {
  animation-delay: 0.0072605526s;
  left: calc((100% - 5em) * 0.28);
}
.particle:nth-of-type(16) {
  animation-delay: 0.8176846865s;
  left: calc((100% - 5em) * 0.3);
}
.particle:nth-of-type(17) {
  animation-delay: 0.3018914328s;
  left: calc((100% - 5em) * 0.32);
}
.particle:nth-of-type(18) {
  animation-delay: 0.0564616792s;
  left: calc((100% - 5em) * 0.34);
}
.particle:nth-of-type(19) {
  animation-delay: 0.3438167677s;
  left: calc((100% - 5em) * 0.36);
}
.particle:nth-of-type(20) {
  animation-delay: 0.4609884814s;
  left: calc((100% - 5em) * 0.38);
}
.particle:nth-of-type(21) {
  animation-delay: 0.1815823768s;
  left: calc((100% - 5em) * 0.4);
}
.particle:nth-of-type(22) {
  animation-delay: 0.6022526488s;
  left: calc((100% - 5em) * 0.42);
}
.particle:nth-of-type(23) {
  animation-delay: 0.8062355157s;
  left: calc((100% - 5em) * 0.44);
}
.particle:nth-of-type(24) {
  animation-delay: 0.1081502892s;
  left: calc((100% - 5em) * 0.46);
}
.particle:nth-of-type(25) {
  animation-delay: 0.0482849543s;
  left: calc((100% - 5em) * 0.48);
}
.particle:nth-of-type(26) {
  animation-delay: 0.7080629489s;
  left: calc((100% - 5em) * 0.5);
}
.particle:nth-of-type(27) {
  animation-delay: 0.5394469728s;
  left: calc((100% - 5em) * 0.52);
}
.particle:nth-of-type(28) {
  animation-delay: 0.3233632889s;
  left: calc((100% - 5em) * 0.54);
}
.particle:nth-of-type(29) {
  animation-delay: 0.6635820546s;
  left: calc((100% - 5em) * 0.56);
}
.particle:nth-of-type(30) {
  animation-delay: 0.9902728658s;
  left: calc((100% - 5em) * 0.58);
}
.particle:nth-of-type(31) {
  animation-delay: 0.8605055595s;
  left: calc((100% - 5em) * 0.6);
}
.particle:nth-of-type(32) {
  animation-delay: 0.7727475419s;
  left: calc((100% - 5em) * 0.62);
}
.particle:nth-of-type(33) {
  animation-delay: 0.1036061676s;
  left: calc((100% - 5em) * 0.64);
}
.particle:nth-of-type(34) {
  animation-delay: 0.463421151s;
  left: calc((100% - 5em) * 0.66);
}
.particle:nth-of-type(35) {
  animation-delay: 0.5988080975s;
  left: calc((100% - 5em) * 0.68);
}
.particle:nth-of-type(36) {
  animation-delay: 0.2985003284s;
  left: calc((100% - 5em) * 0.7);
}
.particle:nth-of-type(37) {
  animation-delay: 0.4188759779s;
  left: calc((100% - 5em) * 0.72);
}
.particle:nth-of-type(38) {
  animation-delay: 0.6282299166s;
  left: calc((100% - 5em) * 0.74);
}
.particle:nth-of-type(39) {
  animation-delay: 0.5750783277s;
  left: calc((100% - 5em) * 0.76);
}
.particle:nth-of-type(40) {
  animation-delay: 0.2442455255s;
  left: calc((100% - 5em) * 0.78);
}
.particle:nth-of-type(41) {
  animation-delay: 0.0379608189s;
  left: calc((100% - 5em) * 0.8);
}
.particle:nth-of-type(42) {
  animation-delay: 0.7449278999s;
  left: calc((100% - 5em) * 0.82);
}
.particle:nth-of-type(43) {
  animation-delay: 0.0430602673s;
  left: calc((100% - 5em) * 0.84);
}
.particle:nth-of-type(44) {
  animation-delay: 0.172150384s;
  left: calc((100% - 5em) * 0.86);
}
.particle:nth-of-type(45) {
  animation-delay: 0.9290053997s;
  left: calc((100% - 5em) * 0.88);
}
.particle:nth-of-type(46) {
  animation-delay: 0.8758812021s;
  left: calc((100% - 5em) * 0.9);
}
.particle:nth-of-type(47) {
  animation-delay: 0.6990750702s;
  left: calc((100% - 5em) * 0.92);
}
.particle:nth-of-type(48) {
  animation-delay: 0.9995281762s;
  left: calc((100% - 5em) * 0.94);
}
.particle:nth-of-type(49) {
  animation-delay: 0.1975731188s;
  left: calc((100% - 5em) * 0.96);
}
.particle:nth-of-type(50) {
  animation-delay: 0.2153737438s;
  left: calc((100% - 5em) * 0.98);
}

@keyframes rise {
  from {
    opacity: 0;
    transform: translateY(0) scale(1);
  }
  25% {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: translateY(-10em) scale(0);
  }
}
