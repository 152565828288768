.teamWrapper {
  height: 100vh;
  overflow-y: scroll;
  font-family: Georgia, "Microsoft YaHei", simsun, serif;
}

.teamWrapper .teamContent {
  padding: 20px;
  background-image: url("../images/new-bg.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: calc(100vh - 140px);
  overflow-y: scroll;
}

.teamWrapper .teamContent .title {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  align-items: center;
}

.teamWrapper .teamContent hr {
  color: var(--color-brown);
}

.teamWrapper .teamContent .title h1 {
  font-variant: small-caps;
  margin-right: 5px;
  color: var(--color-brown);
}

.teamWrapper .teamContent .title p {
  width: 50%;
  text-align: center;
  color: #a6a09a;
  font-family: Georgia, "Microsoft YaHei", simsun, serif;
}

.teamWrapper .teamContent .title a {
  width: 50%;
  text-align: center;
  color: #a6a09a;
  font-family: Georgia, "Microsoft YaHei", simsun, serif;
}

.teamWrapper .teamContent .main {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 20px 0;
}
