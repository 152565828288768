@font-face {
  font-family: Georgia, "Microsoft YaHei", simsun, serif;
  font-weight: normal;
  src: url("./font/chinese.ttf") format("truetype");
}

body {
  margin: 0;
  color: var(--color-brown) !important;
}
