@import url("../common/variables.css");

.headerWrapper {
  background-color: var(--color-dark-grey-80);
  height: 50px;
  font-weight: 600;
  font-family: "Gill Sans", sans-serif;
}

.headerWrapper ul {
  list-style-type: none;
  overflow: hidden;
  margin: 0;
  padding: 0 20px;
  height: 50px;
}

.headerWrapper ul li {
  float: left;
  min-width: 50px;
  height: 50px;
  font-size: 18px;
  display: flex;
  align-items: center;
}

.headerWrapper ul li img {
  width: 170px;
  height: 30px;
}

.headerWrapper ul li.headerRight {
  float: right;
}

.headerWrapper ul li.logoImg a {
  text-decoration: none;
  color: var(--color-brown);
}

.headerWrapper ul li.logoImg a img {
  width: 45px;
  height: 45px;
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}

.headerWrapper ul li.logoImg a img:hover {
  -webkit-animation-name: hvr-buzz-out;
  animation-name: hvr-buzz-out;
  -webkit-animation-duration: 0.75s;
  animation-duration: 0.75s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
}

.headerWrapper ul li.headerRight img {
  width: 25px;
  height: 25px;
  vertical-align: middle;
}

.headerWrapper ul li.headerRight a {
  text-decoration: none;
  color: var(--color-brown);
  padding: 0 5px;
}

.headerWrapper ul li.headerRight a:hover {
  cursor: pointer;
  background-color: var(--color-dark-red);
  background-image: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0) 40%,
    rgba(255, 255, 255, 0.7) 100%
  );
  background-repeat: no-repeat;
  background-size: 200% 100%;
  transition: background-size 1s, background-color 1s;
  border-radius: 3px;
}

.headerWrapper ul li.headerRight .opensea {
  width: 30px;
  height: 30px;
  background-image: url("../images/opensea.png");
  background-position: center;
  background-repeat: no-repeat;
  display: inline-block;
  vertical-align: middle;
}

.headerWrapper ul li.headerRight .opensea:hover {
  width: 50px;
  height: 50px;
  cursor: pointer;
  background-image: url("../images/opensea_click.png");
  background-position: center;
  background-repeat: no-repeat;
}

.headerWrapper ul li.headerRight .twitter {
  width: 70px;
  height: 25px;
  background-image: url("../images/twitter.png");
  background-position: center;
  background-repeat: no-repeat;
  display: inline-block;
  vertical-align: middle;
  background-size: cover;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.headerWrapper ul li.headerRight .twitter:hover {
  width: 70px;
  height: 50px;
  cursor: pointer;
  background-image: url("../images/twitter_click.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.headerWrapper ul li.headerRight .discord {
  width: 70px;
  height: 25px;
  background-image: url("../images/discord.png");
  background-position: center;
  background-repeat: no-repeat;
  display: inline-block;
  vertical-align: middle;
  background-size: cover;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.headerWrapper ul li.headerRight .discord:hover {
  width: 70px;
  height: 50px;
  cursor: pointer;
  background-image: url("../images/discord_click.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.headerWrapper ul li.headerRight button {
  background: none;
  border: none;
}

.headerWrapper .headerRight .dropdownMenu {
  position: fixed !important;
  padding: 0;
}

.headerWrapper .headerRight.icon {
  border-left: 1px solid var(--color-border-grey);
}

.headerWrapper .headerRight.icon:last-child {
  border-left: none;
}

.headerWrapper .headerRight.icon.lng {
  padding-left: 10px;
}

.headerWrapper .headerRight.headerText {
  margin-right: 15px;
  font-weight: 400;
  font-size: 16px;
}

.mobileWrapper {
  display: none;
}

@media screen and (max-width: 585px) {
  .webIcon {
    display: none !important;
  }

  .mobileWrapper {
    display: block;
  }
}

@-webkit-keyframes hvr-buzz-out {
  10% {
    -webkit-transform: translateX(3px) rotate(2deg);
    transform: translateX(3px) rotate(2deg);
  }
  20% {
    -webkit-transform: translateX(-3px) rotate(-2deg);
    transform: translateX(-3px) rotate(-2deg);
  }
  30% {
    -webkit-transform: translateX(3px) rotate(2deg);
    transform: translateX(3px) rotate(2deg);
  }
  40% {
    -webkit-transform: translateX(-3px) rotate(-2deg);
    transform: translateX(-3px) rotate(-2deg);
  }
  50% {
    -webkit-transform: translateX(2px) rotate(1deg);
    transform: translateX(2px) rotate(1deg);
  }
  60% {
    -webkit-transform: translateX(-2px) rotate(-1deg);
    transform: translateX(-2px) rotate(-1deg);
  }
  70% {
    -webkit-transform: translateX(2px) rotate(1deg);
    transform: translateX(2px) rotate(1deg);
  }
  80% {
    -webkit-transform: translateX(-2px) rotate(-1deg);
    transform: translateX(-2px) rotate(-1deg);
  }
  90% {
    -webkit-transform: translateX(1px) rotate(0);
    transform: translateX(1px) rotate(0);
  }
  100% {
    -webkit-transform: translateX(-1px) rotate(0);
    transform: translateX(-1px) rotate(0);
  }
}
@keyframes hvr-buzz-out {
  10% {
    -webkit-transform: translateX(3px) rotate(2deg);
    transform: translateX(3px) rotate(2deg);
  }
  20% {
    -webkit-transform: translateX(-3px) rotate(-2deg);
    transform: translateX(-3px) rotate(-2deg);
  }
  30% {
    -webkit-transform: translateX(3px) rotate(2deg);
    transform: translateX(3px) rotate(2deg);
  }
  40% {
    -webkit-transform: translateX(-3px) rotate(-2deg);
    transform: translateX(-3px) rotate(-2deg);
  }
  50% {
    -webkit-transform: translateX(2px) rotate(1deg);
    transform: translateX(2px) rotate(1deg);
  }
  60% {
    -webkit-transform: translateX(-2px) rotate(-1deg);
    transform: translateX(-2px) rotate(-1deg);
  }
  70% {
    -webkit-transform: translateX(2px) rotate(1deg);
    transform: translateX(2px) rotate(1deg);
  }
  80% {
    -webkit-transform: translateX(-2px) rotate(-1deg);
    transform: translateX(-2px) rotate(-1deg);
  }
  90% {
    -webkit-transform: translateX(1px) rotate(0);
    transform: translateX(1px) rotate(0);
  }
  100% {
    -webkit-transform: translateX(-1px) rotate(0);
    transform: translateX(-1px) rotate(0);
  }
}
